import {IIconComponent} from "../../../services/types/types";
import {FC} from "react";

export const CloseIcon: FC<IIconComponent> = ({className, fill}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1 1L11 11M11 1L1 11" stroke={fill ? fill : "#1D1D1D"} strokeWidth="2"/>
      <path d="M1 1L11 11M11 1L1 11" stroke={fill ? fill : "#1D1D1D"} strokeOpacity="0.2" strokeWidth="2"/>
      <path d="M1 1L11 11M11 1L1 11" stroke={fill ? fill : "#1D1D1D"} strokeOpacity="0.2" strokeWidth="2"/>
      <path d="M1 1L11 11M11 1L1 11" stroke={fill ? fill : "#1D1D1D"} strokeOpacity="0.2" strokeWidth="2"/>
      <path d="M1 1L11 11M11 1L1 11" stroke={fill ? fill : "#1D1D1D"} strokeOpacity="0.2" strokeWidth="2"/>
    </svg>
  
  );
};
