import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const TwoGisMiniLogoIcon: FC<IIconComponent> = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#19AA1E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2.22249C0 0.995041 0.995041 0 2.22249 0H20.3686C22.3742 0 24 1.62584 24 3.63141C24 5.86046 22.0104 7.56336 19.8081 7.2193L1.87943 4.41834C0.797559 4.24932 0 3.31748 0 2.22249Z" fill="#FFB919" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 22.5847C0 21.8878 0.507615 21.2947 1.19618 21.1872L20.6063 18.1548C22.3893 17.8762 24 19.2548 24 21.0594C24 22.683 22.6838 23.9993 21.0601 23.9993H1.41452C0.633304 23.9993 0 23.366 0 22.5847Z" fill="#82D714" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 4.19793C0 3.91036 0.256677 3.69068 0.540793 3.73507L6.89106 4.7273C7.84921 4.87701 8.81376 4.62602 9.73054 4.30983C10.4379 4.06586 11.2013 3.93709 11.9996 3.93709C13.854 3.93709 15.5208 4.63158 16.7338 5.85037C17.0274 6.14678 17.3945 6.36815 17.8068 6.43254L23.6036 7.33812C23.8318 7.37377 24 7.57032 24 7.80129C24 8.08906 23.7431 8.3089 23.4588 8.26444L20.7846 7.84634C19.5743 7.65713 18.5601 9.01046 18.562 10.2354C18.562 11.5211 18.2747 12.8032 17.6798 14.0904L17.6624 14.1296C17.5082 14.4592 17.1769 14.6707 16.813 14.6707C15.5797 14.6707 14.7623 15.0394 14.2271 15.6931C13.8075 16.2058 13.5646 16.8981 13.4831 17.6529L13.4802 17.6762L13.4686 17.7969L13.4635 17.8493L13.4533 17.9518C13.4124 18.3932 13.8008 18.7437 14.2388 18.6752L23.4588 17.2343C23.7431 17.1899 24 17.4097 24 17.6975C24 17.9284 23.8318 18.125 23.6036 18.1607L0.541188 21.7644C0.256865 21.8088 0 21.5889 0 21.3012C0 21.0702 0.168232 20.8736 0.396439 20.838L10.4103 19.2733C10.5309 19.2545 10.619 19.1494 10.6165 19.0274L10.615 18.9677L10.6136 18.9365L10.6121 18.8892C10.6027 18.631 10.5787 18.3002 10.5409 17.8987L10.5336 17.8224L10.5205 17.6908C10.4449 16.9316 10.2056 16.2313 9.78602 15.7106C9.25734 15.0524 8.44796 14.6779 7.22407 14.6707H7.18694C6.82275 14.6707 6.49154 14.4597 6.33761 14.1296C5.73112 12.8294 5.43732 11.5342 5.43732 10.2354C5.43732 8.60213 6.09981 7.02263 7.26625 5.85037C7.30826 5.80926 7.2854 5.73783 7.22732 5.72874L0.396112 4.66078C0.168089 4.62513 0 4.42872 0 4.19793Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9997 4.87445C15.3507 4.87445 17.6246 7.45313 17.6246 10.2354C17.6246 10.5872 17.6005 10.9434 17.5496 11.3044C17.3328 12.8434 15.6955 13.5274 14.3707 14.3401C13.0669 15.1399 12.6534 16.5642 12.548 17.5802L12.5378 17.6784C12.5043 18.0228 12.4797 18.3229 12.4644 18.5785C12.4513 18.7962 12.2911 18.9791 12.0756 19.0128L11.5834 19.0898C11.5683 19.0921 11.5546 19.0804 11.5546 19.0652C11.5422 18.5926 11.5102 18.1207 11.4587 17.6507L11.4565 17.6246C11.3586 16.606 10.9522 15.1507 9.62805 14.3392C8.3035 13.5275 6.66713 12.8443 6.45002 11.306C6.39899 10.9444 6.37476 10.5877 6.37476 10.2354C6.37476 7.45313 8.64946 4.87445 11.9997 4.87445Z" fill="#0073FA" />
    </svg>
  );
};