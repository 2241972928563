import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const DoubleRepeatIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.9773 19.4999C18.7345 18.3363 20.0276 16.5929 20.6312 14.5737C21.2348 12.5544 21.1106 10.3874 20.2803 8.45034C19.45 6.51327 17.9662 4.92902 16.0876 3.97374C14.209 3.01847 12.0547 2.75276 10.0003 3.22293M16.9773 19.4999V15.9999M16.9773 19.4999H20.5003M7.00034 4.51593C5.24924 5.68384 3.96288 7.42855 3.36494 9.44667C2.767 11.4648 2.89531 13.6286 3.72756 15.562C4.5598 17.4953 6.04334 19.0757 7.92019 20.0285C9.79704 20.9813 11.9485 21.2461 14.0003 20.7769M7.00034 4.51593V7.99993M7.00034 4.51593H3.50034"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
