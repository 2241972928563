import { AddToCartIcon } from "./add-to-cart-icon/add-to-cart-icon";
import { AppleIcon } from "./apple-icon/apple-icon";
import { ArrowFillIcon } from "./arrow-fill-icon/arrow-fill-icon";
import { ArrowIcon } from "./arrow-icon/arrow-icon";
import { BoxIcon } from "./box-icon/box-icon";
import { BurgerIcon } from "./burger-icon/burger-icon";
import { CartIcon } from "./cart-icon/cart-icon";
import { CatalogIcon } from "./catalog-icon/catalog-icon";
import { ChatIcon } from "./chat-icon/chat-icon";
import { CheckedIcon } from "./checked-icon/checked-icon";
import { ChevronIcon } from "./chevron-icon/chevron-icon";
import { CloseIcon } from "./close-icon/close-icon";
import { DateIcon } from "./date-icon/date-icon";
import { DeliveryIcon } from "./delivery-icon/delivery-icon";
import { DzenIcon } from "./dzen-icon/dzen-icon";
import { FavouriteIcon } from "./favourite-icon/favourite-icon";
import { FilterIcon } from "./filter-icon/filter-icon";
import { GeoIcon } from "./geo-icon/geo-icon";
import { GoldWarrantyIcon } from "./gold-warranty-icon/gold-warranty-icon";
import { GoogleplayIcon } from "./googleplay-icon/googleplay-icon";
import { HomeIcon } from "./home-icon/home-icon";
import { InCartIcon } from "./in-cart-icon/in-cart-icon";
import { MainIcon } from "./main-icon/main";
import { MastercardIcon } from "./mastercard-icon/mastercard-icon";
import { MenuIcon } from "./menu-icon/menu-icon";
import { MirIcon } from "./mir-icon/mir-icon";
import { NotAvailableIcon } from "./not-available-icon/not-available-icon";
import { OkIcon } from "./ok-icon/ok-icon";
import { PayErrorIcon } from "./pay-error-icon/pay-error-icon";
import { PaySuccessIcon } from "./pay-success-icon/pay-success-icon";
import { QuestionCallIcon } from "./question-call-icon/question-call-icon";
import { QuestionChatIcon } from "./question-chat-icon/question-chat-icon";
import { QuestionMailIcon } from "./question-mail-icon/question-mail-icon";
import { RemoveIcon } from "./remove-icon/remove-icon";
import { RepeatIcon } from "./repeat-icon/repeat-icon";
import { RestoreIcon } from "./restore-icon/restore-icon";
import { RutubeIcon } from "./rutube-icon/rutube-icon";
import { SearchIcon } from "./search-icon/search-icon";
import { ShareIcon } from "./share-icon/share-icon";
import {
  StarActiveIcon,
  StarIcon,
  StarIconHalfIcon,
} from "./star-icon/star-icon";
import { StatusIcon } from "./status-icon/status-icon";
import { TagIcon, TagTransparentIcon } from "./tag-icon/tag-icon";
import { TelHeaderIcon } from "./tel-header-icon/tel-header-icon";
import { TelIcon } from "./tel-icon/tel-icon";
import { TgIcon } from "./tg-icon/tg-icon";
import { VisaIcon } from "./visa-icon/visa-icon";
import { VkColorIcon } from "./vk-color-icon/vk-color-icon";
import { VkIcon } from "./vk-icon/vk-icon";
import { WorktimeIcon } from "./worktime-icon/worktime-icon";
import { YaColorIcon } from "./ya-color-icon/ya-color-icon";
import { YoutubeIcon } from "./youtube-icon/youtube-icon";
import { ArrowSearch } from "./arrow-seach/arrow-search";
import { TgColorIcon } from "./tg-color-icon/tg-color-icon";
import { MailRuColorIcon } from "./mailru-color-icon/mailru-color-icon";
import { MailIcon } from "./mail-icon/mail-icon";
import { TelBlackIcon } from "./tel-black-icon/tel-black-icon";
import { OkColorIcon } from "./ok-color-icon/ok-color-icon";
import { ArrowBackIcon } from "./arrow-back-icon/arrow-back-icon";
import { CityIcon } from "./city-icon/city-icon";
import { CardAnswerExpertIcon } from "./card-answer-expert-icon/card-answer-expert-icon";
import { CancelIcon } from "./cancel-icon/cancel-icon";
import { CopyIcon } from "./copy-icon/copy-icon";
import { CursorIcon } from "./cursor-icon/cursor-icon";
import { CallbackIcon } from "./callback-icon/callback-icon";
import { SoundMutedIcon } from "./sound-muted-icon/sound-muted-icon";
import { SoundIcon } from "./sound-icon/sound-icon";
import { PaletteIcon } from "./palette-icon/palette-icon";
import { UploadFileIcon } from "./upload-file-icon/upload-file-icon";
import { UnderlineIcon } from "./undeline-icon/underline-icon";
import { AlignCenterIcon } from "./align-center-icon/align-center-icon";
import { ItalicIcon } from "./italic-icon/italic-icon";
import { BoldIcon } from "./bold-icon/bold-icon";
import { CodeIcon } from "./code-icon/code-icon";
import { AlignJustifyIcon } from "./align-justify-icon/align-justify-icon";
import { AlignLeftIcon } from "./align-left-icon/align-left-icon";
import { AlignRightIcon } from "./align-right-icon/align-right-icon";
import { FormatQuoteIcon } from "./format-quote-icon/format-quote-icon";
import { ListBulletedIcon } from "./list-bulleted-icon/list-bulleted-icon";
import { ListNumberedIcon } from "./list-numbered-icon/list-numbered-icon";
import { OneSquareIcon } from "./one-square-icon/one-square-icon";
import { TwoSquareIcon } from "./two-square-icon/two-square-icon";
import { ImageIcon } from "./image-icon/image-icon";
import { DropArrowIcon } from "./drop-arrow-icon/drop-arrow-icon";
import { LinkIcon } from "./link-icon/link-icon";
import { EditIcon } from "./edit-icon/edit-icon";
import { BasketIcon } from "./basket-icon/basket-icon";
import { OpenArrowIcon } from "./open-arrow-icon/open-arrow-icon";
import { PlusIcon } from "./plus-icon/plus-icon";
import { ReduceIcon } from "./reduce-icon/reduce-icon";
import { TextImageRightIcon } from "./text-image-right-icon/text-image-right-icon";
import { TextImageLeftIcon } from "./text-image-left-icon/text-image-left-icon";
import { ResizeImageIcon } from "./resize-image-icon/resize-image-icon";
import { TicketActiveIcon } from "./ticket-active-icon/ticket-active-icon";
import { BigTriangle } from "./big-triangle/big-triangle";
import { TicketIcon } from "./ticket/ticket-icon";
import { ShopsIcon } from "./shops-icon/shops-icon";
import { BlogsIcon } from "./blogs-icon/blogs-icon";
import { LicenseIcon } from "./license-icon/license-icon";
import { SaleIcon } from "./sale-icon/sale-icon";
import { RemIcon } from "./rem-icon/rem-icon";
import { BrandsIcon } from "./brands-icon/brands-icon";
import { AppStoreMiniIcon } from "./app-store-mini-icon/app-store-mini-icon";
import { GooglePlayMiniIcon } from "./google-play-mini-icon/google-play-mini-icon";
import { RustoreMiniIcon } from "./rustore-mini-icon/rustore-mini-icon";
import { AppQrCodeIcon } from "./app-qr-code-icon/app-qr-code-icon";
import { GooglePlayNormalIcon } from "./google-play-normal-icon/google-play-normal-icon";
import { AppStoreNormalIcon } from "./app-store-normal-icon/app-store-normal-icon";
import { RuStoreNormalIcon } from "./ruStore-normal-icon/ruStore-normal-icon";
import { NewLogoOpacityIcon } from "./new-logo-opacity-icon/new-logo-opacity-icon";
import { YandexMiniLogoIcon } from "./yandex-mini-logo-icon/yandex-mini-logo-icon";
import { TwoGisMiniLogoIcon } from "./two-gis-mini-logo-icon/two-gis-mini-logo-icon";
import { BigHeartIcon } from "./big-heart-icon/big-heart-icon";
import { DoubleRepeatIcon } from "./double-repeat-icon/double-repeat-icon";
import { SuccessIcon } from "./success-icon/success-icon";
import { ErrorIcon } from "./error-icon/error-icon";
import EmailCodeIcon from "./email-code-icon/email-code-icon";
import InfoIcon from "./InfoIcon/index";

export {
  AppQrCodeIcon,
  RustoreMiniIcon,
  GooglePlayMiniIcon,
  AppStoreMiniIcon,
  CallbackIcon,
  CursorIcon,
  CancelIcon,
  CardAnswerExpertIcon,
  CopyIcon,
  ArrowBackIcon,
  OkColorIcon,
  TelBlackIcon,
  RepeatIcon,
  FilterIcon,
  WorktimeIcon,
  TelHeaderIcon,
  StatusIcon,
  CatalogIcon,
  MenuIcon,
  MainIcon,
  ChatIcon,
  CheckedIcon,
  RestoreIcon,
  YaColorIcon,
  VkColorIcon,
  RemoveIcon,
  ArrowIcon,
  NotAvailableIcon,
  InCartIcon,
  AddToCartIcon,
  QuestionChatIcon,
  QuestionMailIcon,
  QuestionCallIcon,
  ShareIcon,
  BoxIcon,
  DeliveryIcon,
  TagTransparentIcon,
  StarActiveIcon,
  StarIcon,
  GoldWarrantyIcon,
  TagIcon,
  GeoIcon,
  TelIcon,
  CloseIcon,
  SearchIcon,
  BurgerIcon,
  CartIcon,
  FavouriteIcon,
  VkIcon,
  TgIcon,
  DzenIcon,
  OkIcon,
  YoutubeIcon,
  RutubeIcon,
  ArrowFillIcon,
  MastercardIcon,
  MirIcon,
  VisaIcon,
  AppleIcon,
  GoogleplayIcon,
  DateIcon,
  HomeIcon,
  ChevronIcon,
  PayErrorIcon,
  PaySuccessIcon,
  ArrowSearch,
  TgColorIcon,
  MailRuColorIcon,
  MailIcon,
  CityIcon,
  SoundMutedIcon,
  SoundIcon,
  PaletteIcon,
  UploadFileIcon,
  UnderlineIcon,
  AlignCenterIcon,
  ItalicIcon,
  BoldIcon,
  CodeIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  FormatQuoteIcon,
  ListBulletedIcon,
  ListNumberedIcon,
  OneSquareIcon,
  TwoSquareIcon,
  ImageIcon,
  DropArrowIcon,
  LinkIcon,
  EditIcon,
  BasketIcon,
  OpenArrowIcon,
  PlusIcon,
  ReduceIcon,
  TextImageRightIcon,
  TextImageLeftIcon,
  ResizeImageIcon,
  BigTriangle,
  TicketActiveIcon,
  TicketIcon,
  ShopsIcon,
  BlogsIcon,
  LicenseIcon,
  SaleIcon,
  RemIcon,
  BrandsIcon,
  AppStoreNormalIcon,
  GooglePlayNormalIcon,
  RuStoreNormalIcon,
  StarIconHalfIcon,
  NewLogoOpacityIcon,
  YandexMiniLogoIcon,
  TwoGisMiniLogoIcon,
  BigHeartIcon,
  DoubleRepeatIcon,
  SuccessIcon,
  ErrorIcon,
  EmailCodeIcon,
  InfoIcon
};
