import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const MirIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="13"
      viewBox="0 0 45 13"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16124 0.00209863C4.56326 -0.000311008 5.75804 -0.108125 6.26413 1.60318C6.60503 2.75594 7.14807 4.64421 7.89325 7.26798H8.19673C8.99587 4.50181 9.54483 2.61355 9.84363 1.60318C10.355 -0.125951 11.6334 0.00213251 12.1447 0.00213251L16.09 0.00213744V12.2982H12.0689V5.05191H11.7992L9.55767 12.2982H6.53231L4.29074 5.04654H4.0211V12.2982H0V0.00213744L4.16124 0.00209863ZM21.8642 0.00213744V7.25379H22.185L24.9116 1.29081C25.4409 0.104157 26.569 0.00213744 26.569 0.00213744H30.4602V12.2982H26.3552V5.04654H26.0344L23.3612 11.0095C22.8319 12.1908 21.6503 12.2982 21.6503 12.2982H17.7591V0.00213744H21.8642ZM44.6492 5.84531C44.0766 7.47107 42.2784 8.63539 40.2875 8.63539H35.9825V12.2982H32.0788V5.84531H44.6492Z"
        fill={fill ? fill : "#F7F8F9"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4762 0.00195312H31.8745C32.0792 2.73947 34.4322 5.08297 36.868 5.08297H44.9209C45.3856 2.80777 43.7858 0.00195312 40.4762 0.00195312Z"
        fill={fill ? fill : "#F7F8F9"}
      />
    </svg>
  );
};
