import { IIconComponent } from "@/services/types/types";
import Link from "next/link";
import { FC } from "react";

export const TicketIcon: FC<IIconComponent> = ({ className, code, name, ticket }) => {
  return (
    // <svg
    //   width="400"
    //   height="200"
    //   viewBox="0 0 400 200"
    //   className={className}
    // >
    //   <path d="M304 0H16A16 16 0 0 0 0 16V184A16 16 0 0 0 16 200H324V0Z" fill="#ffdd2d" />
    //   <foreignObject x="0" y="0" width="286" height="200">
    //     <div
    //       style={{
    //         width: "100%",
    //         height: "100%",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         flexDirection: "column",
    //         textAlign: "left",
    //         padding: "10px",
    //         boxSizing: "border-box",
    //       }}
    //     >
    //       <p
    //         style={{
    //           fontSize: "20px",
    //           color: "#000",
    //           margin: 0,
    //         }}
    //       >
    //         {name}
    //       </p>
    //       <Link href={`/giveaway/${code}/`}>Подпробнее</Link>
    //     </div>
    //   </foreignObject>
    //   <rect x="286" y="0" width="80" height="200" fill="#fcc521" />
    //   <circle cx="286" cy="0" r="10" fill="#FFFFFF" />
    //   <circle cx="286" cy="200" r="10" fill="#FFFFFF" />
    //   <line x1="286" y1="10" x2="286" y2="190" stroke="#000" strokeWidth="2" strokeDasharray="5,5" />
    //   <text
    //     x="336"
    //     y="100"
    //     fontSize="20"
    //     fill="#000"
    //     textAnchor="middle"
    //     dominantBaseline="middle"
    //     transform="rotate(-90 330, 100)"
    //   >
    //     №{ticket}
    //   </text>
    // </svg>
    <svg
      width="409"
      height="200"
      viewBox="0 0 409 200"
      className={className}
    >
      <g clipPath="url(#clip0_546_1001)">
        <path d="M339.862 0H17.8875C13.1434 0 8.59367 1.68571 5.23912 4.68629C1.88457 7.68687 0 11.7565 0 16L0 184C0 188.243 1.88457 192.313 5.23912 195.314C8.59367 198.314 13.1434 200 17.8875 200H362.221V0H339.862Z" fill="#FFDD2D" />
        <path d="M409.172 0H319.734V200H409.172V0Z" fill="#FCC521" />
        <path d="M319.5 10.0006C325.299 10.0006 330 5.52346 330 0.000611067C329.999 0.135414 325.299 0 319.5 0C313.701 0 309 0.00271264 309 0.000611067C309 5.52346 313.701 10.0006 319.5 10.0006Z" fill="white" />
        <path d="M319.5 210C324.747 210 329 205.523 329 200C329 194.477 324.747 190 319.5 190C314.253 190 310 194.477 310 200C310 205.523 314.253 210 319.5 210Z" fill="white" />
        <path d="M319.734 10V190" stroke="black" stroke-dasharray="2 2" />
      </g>
      <foreignObject x="0" y="0" width="286" height="200">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "left",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              color: "#000",
              margin: 0,
            }}
          >
            {name}
          </p>
          <Link prefetch={false} href={`/giveaway/${code}/`}>Подпробнее</Link>
        </div>
      </foreignObject>
      <text
        x="336"
        y="100"
        fontSize="20"
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
        transform="rotate(-90 330, 100)"
      >
        №{ticket}
      </text>
      <defs>
        <clipPath id="clip0_546_1001">
          <rect width="409" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg >

  )
};