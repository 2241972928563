import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const VkColorIcon: FC<IIconComponent> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M0 15.36C0 8.11923 0 4.49884 2.24942 2.24942C4.49884 0 8.11923 0 15.36 0H16.64C23.8808 0 27.5012 0 29.7506 2.24942C32 4.49884 32 8.11923 32 15.36V16.64C32 23.8808 32 27.5012 29.7506 29.7506C27.5012 32 23.8808 32 16.64 32H15.36C8.11923 32 4.49884 32 2.24942 29.7506C0 27.5012 0 23.8808 0 16.64V15.36Z"
        fill="#0077FF"
      />
      <path
        d="M17.0884 23.3333C9.86844 23.3333 5.48804 18.324 5.31641 10H8.97314C9.08714 16.1146 11.8691 18.7057 14.0016 19.2386V10H17.506V15.277C19.563 15.049 21.716 12.6487 22.4397 10H25.8889C25.3369 13.2571 22.993 15.6574 21.3369 16.6475C22.9943 17.448 25.6609 19.5434 26.6894 23.3333H22.8982C22.0977 20.7999 20.1368 18.8377 17.5072 18.5712V23.3333H17.0884Z"
        fill="white"
      />
    </svg>
  );
};
