const InfoIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" fill="#1D1D1D"/>
      <path
        d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 18C11.34 18 10.8 17.46 10.8 16.8V12C10.8 11.34 11.34 10.8 12 10.8C12.66 10.8 13.2 11.34 13.2 12V16.8C13.2 17.46 12.66 18 12 18ZM13.2 8.4H10.8V6H13.2V8.4Z"
        fill="#FFDD2D"/>
    </svg>
  
  );
};

export  default InfoIcon;