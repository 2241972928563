import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const MailIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.83301 4.41992H20.833C21.933 4.41992 22.833 5.31992 22.833 6.41992V18.4199C22.833 19.5199 21.933 20.4199 20.833 20.4199H4.83301C3.73301 20.4199 2.83301 19.5199 2.83301 18.4199V6.41992C2.83301 5.31992 3.73301 4.41992 4.83301 4.41992Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.833 6.41992L12.833 13.4199L2.83301 6.41992"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
