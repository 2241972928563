import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const VkIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <path
        d="M18.6331 7.60948C18.7449 7.25786 18.6331 7 18.1046 7H16.3535C15.908 7 15.7044 7.22307 15.5926 7.46807C15.5926 7.46807 14.7023 9.52411 13.4407 10.8573C13.0335 11.2452 12.8475 11.3677 12.6255 11.3677C12.5145 11.3677 12.3532 11.2452 12.3532 10.8935V7.60948C12.3532 7.18753 12.2247 7 11.8534 7H9.10187C8.82401 7 8.65633 7.19509 8.65633 7.38111C8.65633 7.78037 9.28711 7.87263 9.35179 8.99782V11.4387C9.35179 11.9734 9.25039 12.0709 9.02681 12.0709C8.43356 12.0709 6.98993 10.0073 6.13318 7.64502C5.9663 7.18602 5.79782 7.00076 5.35068 7.00076H3.60045C3.09981 7.00076 3 7.22383 3 7.46883C3 7.90892 3.59326 10.0867 5.76349 12.967C7.21031 14.9338 9.24719 16 11.1028 16C12.2151 16 12.3524 15.7633 12.3524 15.355V13.8683C12.3524 13.395 12.4586 13.2997 12.8107 13.2997C13.0694 13.2997 13.515 13.4237 14.5538 14.3712C15.7403 15.4949 15.9367 15.9992 16.6035 15.9992H18.3537C18.8535 15.9992 19.1034 15.7626 18.9597 15.2952C18.8024 14.8302 18.2355 14.1534 17.4834 13.3526C17.0745 12.8959 16.4637 12.4044 16.2777 12.1586C16.0182 11.8418 16.0932 11.7019 16.2777 11.4206C16.2785 11.4214 18.4112 8.57663 18.6331 7.60948Z"
        fill={fill ? fill : "#C9CED6"}
      />
    </svg>
  );
};
