import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const QuestionMailIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      className={className}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="#FFE53E" />
      <rect
        x="9.8"
        y="11.8"
        width="17.4"
        height="13.4"
        rx="2.2"
        stroke="#161616"
        strokeWidth="1.6"
      />
      <path
        d="M10 14L16.9789 18.1053C17.9178 18.6575 19.0822 18.6575 20.0211 18.1053L27 14"
        stroke="#161616"
        strokeWidth="1.6"
      />
    </svg>
  );
};
