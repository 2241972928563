import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const OkIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <path
        d="M11.0062 10.7737C13.4701 10.7729 15.4658 8.80774 15.465 6.38528C15.4642 3.96204 13.4661 1.99922 11.003 2C8.53993 2.00078 6.5434 3.96595 6.5442 6.38841C6.54738 8.81008 8.54391 10.7721 11.0062 10.7737ZM11.0062 4.57176C12.0267 4.57176 12.8541 5.3855 12.8541 6.38919C12.8541 7.39288 12.0267 8.20584 11.0062 8.20584C9.98567 8.20584 9.15829 7.3921 9.15829 6.38841C9.15988 5.3855 9.98647 4.57254 11.0062 4.57176ZM12.8096 14.353C13.7276 14.1482 14.6034 13.791 15.4006 13.2978C16.0079 12.9124 16.1827 12.1166 15.7909 11.5194C15.4054 10.9316 14.6122 10.7541 14.0066 11.1208C12.1698 12.2503 9.83784 12.2503 8.00106 11.1208C7.39145 10.7448 6.58632 10.9246 6.20243 11.5233C5.81696 12.1229 5.99817 12.9171 6.60858 13.2962L6.61016 13.297C7.40655 13.7894 8.28242 14.1467 9.19962 14.3523L6.70554 16.8052C6.20879 17.3211 6.23184 18.1356 6.7572 18.6242C7.2619 19.0932 8.05034 19.0932 8.55504 18.6242L11.0046 16.2135L13.4558 18.6242C13.966 19.1253 14.7918 19.1253 15.3021 18.6242C15.8115 18.1224 15.8115 17.3102 15.3021 16.8083L12.8096 14.353Z"
        fill={fill ? fill : "#C9CED6"}
      />
    </svg>
  );
};
