import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const TgIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <path
        d="M3.91962 10.6615C3.91962 10.6615 10.5538 7.80779 12.8546 6.80291C13.7366 6.401 16.7277 5.11476 16.7277 5.11476C16.7277 5.11476 18.1082 4.55207 17.9932 5.91864C17.9548 6.48138 17.6481 8.45085 17.3413 10.5811C16.8811 13.5957 16.3826 16.8916 16.3826 16.8916C16.3826 16.8916 16.3059 17.8161 15.654 17.9768C15.0021 18.1376 13.9283 17.4142 13.7366 17.2533C13.5832 17.1328 10.8605 15.324 9.8635 14.4398C9.59505 14.1986 9.28829 13.7163 9.90182 13.1536C11.2824 11.8272 12.9313 10.1792 13.9283 9.13419C14.3885 8.65184 14.8487 7.52642 12.9313 8.89299C10.2086 10.8625 7.5243 12.7114 7.5243 12.7114C7.5243 12.7114 6.91072 13.1134 5.76031 12.7516C4.60984 12.3899 3.26767 11.9076 3.26767 11.9076C3.26767 11.9076 2.34739 11.3047 3.91962 10.6615Z"
        fill={fill ? fill : "#C9CED6"}
      />
    </svg>
  );
};
