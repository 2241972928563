import { IIconComponent } from "@/services/types/types";
import { FC } from "react";


export const SoundMutedIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      className={className}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1"
    >
      {/* <g clipPath="url(#clip0_15_183)">
        <path d="M3 16V8H6L11 4V20L6 16H3Z" strokeWidth="3" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 15L20.5 9" strokeWidth="3" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 9L20.5 15" strokeWidth="3" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_15_183">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs> */}
      <path d="M11.38,4.08a1,1,0,0,0-1.09.21L6.59,8H4a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H6.59l3.7,3.71A1,1,0,0,0,11,20a.84.84,0,0,0,.38-.08A1,1,0,0,0,12,19V5A1,1,0,0,0,11.38,4.08Z" >
      </path>
      <path d="M16,15.5a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l5-5a1,1,0,0,1,1.42,1.42l-5,5A1,1,0,0,1,16,15.5Z">
      </path>
      <path
        d="M21,15.5a1,1,0,0,1-.71-.29l-5-5a1,1,0,0,1,1.42-1.42l5,5a1,1,0,0,1,0,1.42A1,1,0,0,1,21,15.5Z">
      </path>
    </svg >
  );
};