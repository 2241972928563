import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const StarActiveIcon: FC<IIconComponent> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
    >
      <path
        d="M8.5 0L10.7483 5.08754L16.584 5.52786L12.1378 9.11246L13.4962 14.4721L8.5 11.6L3.50383 14.4721L4.86221 9.11246L0.416019 5.52786L6.25172 5.08754L8.5 0Z"
        fill="#FCD717"
      />
    </svg>
  );
};

export const StarIcon: FC<IIconComponent> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
    >
      <path
        d="M8.5 0L10.7483 5.08754L16.584 5.52786L12.1378 9.11246L13.4962 14.4721L8.5 11.6L3.50383 14.4721L4.86221 9.11246L0.416019 5.52786L6.25172 5.08754L8.5 0Z"
        fill={fill ? fill : "#E9E9E9"}
      />
    </svg>
  );
};

export const StarIconHalfIcon: FC<IIconComponent> = ({ fill, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
    >
      <defs>
        <linearGradient id="halfFill" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="50%" stopColor="#FCD717" />
          <stop offset="50%" stopColor="#E9E9E9" stopOpacity="1" />
        </linearGradient>
      </defs>
      <path d="M8.5 0L10.7483 5.08754L16.584 5.52786L12.1378 9.11246L13.4962 14.4721L8.5 11.6L3.50383 14.4721L4.86221 9.11246L0.416019 5.52786L6.25172 5.08754L8.5 0Z" fill="url(#halfFill)"></path>
    </svg>
  )
}