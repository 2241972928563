import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const ArrowFillIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="12"
      viewBox="0 0 5 12"
      fill="none"
      className={className}
    >
      <path
        d="M-4.80825e-07 0.5L0 11.5L5 6L-4.80825e-07 0.5Z"
        fill={fill ? fill : "#FFDD2D"}
      />
    </svg>
  );
};
