import {IIconComponent} from "@/services/types/types";

const EmailCodeIcon = (props: IIconComponent) => {
  const {className, fill} = props;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.4998 12.0005C22.4998 12.0952 22.4729 12.188 22.4222 12.268C22.3715 12.348 22.2992 12.412 22.2135 12.4525L4.21354 20.9525C4.12363 20.9961 4.0227 21.0116 3.92385 20.9971C3.825 20.9826 3.73281 20.9386 3.65926 20.871C3.5857 20.8034 3.53419 20.7152 3.51141 20.6179C3.48864 20.5207 3.49565 20.4188 3.53154 20.3255L6.37354 12.6985C6.54121 12.2483 6.54121 11.7528 6.37354 11.3025L3.53054 3.67552C3.49447 3.58217 3.48736 3.48011 3.51015 3.38265C3.53294 3.2852 3.58456 3.19687 3.65829 3.12919C3.73202 3.06151 3.82443 3.01761 3.92347 3.00322C4.02252 2.98883 4.1236 3.00462 4.21354 3.04852L22.2135 11.5485C22.2992 11.589 22.3715 11.653 22.4222 11.733C22.4729 11.813 22.4998 11.9058 22.4998 12.0005ZM22.4998 12.0005H6.49954"
        stroke={fill ? fill : "#212121"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EmailCodeIcon;