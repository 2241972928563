import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const QuestionCallIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      className={className}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="#FFE53E" />
      <path
        d="M20.826 21.4843L20.7941 21.5162L20.766 21.5514L19.8666 22.68C18.0549 21.6737 16.3835 20.0492 15.3453 18.1963L16.4852 17.2258L16.5152 17.2003L16.5425 17.172C16.9778 16.7206 17.1063 16.0881 16.9299 15.5268L16.93 15.5268L16.9256 15.5137C16.6456 14.6736 16.5 13.7676 16.5 12.825C16.5 11.9332 15.7668 11.2 14.875 11.2H11.9917C11.6682 11.2 11.248 11.2687 10.8825 11.513C10.4748 11.7856 10.2 12.2381 10.2 12.825C10.2 21.0088 17.0001 27.8 25.175 27.8C25.747 27.8 26.1913 27.5287 26.4643 27.1397C26.7143 26.7833 26.8 26.3643 26.8 26.0167V23.1417C26.8 22.2498 26.0668 21.5167 25.175 21.5167C24.2387 21.5167 23.332 21.3647 22.4853 21.0907C21.902 20.8939 21.2616 21.0487 20.826 21.4843Z"
        stroke="black"
        strokeWidth="1.6"
      />
    </svg>
  );
};
