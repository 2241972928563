import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const ChatIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M21 10V14.5C21 15.6046 20.1046 16.5 19 16.5H18V18.3131C18 18.393 17.911 18.4407 17.8445 18.3964L15 16.5H11C9.89543 16.5 9 15.6046 9 14.5V10C9 8.89543 9.89543 8 11 8H19C20.1046 8 21 8.89543 21 10Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="1.5"
      />
      <path
        d="M3 6V10.5C3 11.6046 3.89543 12.5 5 12.5H6V14.3131C6 14.393 6.08901 14.4407 6.15547 14.3964L9 12.5H13C14.1046 12.5 15 11.6046 15 10.5V6C15 4.89543 14.1046 4 13 4H5C3.89543 4 3 4.89543 3 6Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="1.5"
      />
      <circle cx="6.2" cy="8.2" r="0.7" fill="#1D1D1D" />
      <circle cx="9.0999" cy="8.2" r="0.7" fill="#1D1D1D" />
      <circle cx="12" cy="8.2" r="0.7" fill="#1D1D1D" />
    </svg>
  );
};
