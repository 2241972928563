import { FC } from "react";
import { IIconComponent } from "@/services/types/types";

export const TwoSquareIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      className={className}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2 2H22V22H2V2ZM9.15723 17H15.9795V15.4072H11.7002L13.9629 13.0215C14.5827 12.3424 15.0202 11.7477 15.2754 11.2373C15.5306 10.7269 15.6582 10.2256 15.6582 9.7334C15.6582 8.83561 15.3734 8.14062 14.8037 7.64844C14.2386 7.15625 13.4434 6.91016 12.418 6.91016C11.748 6.91016 11.1488 7.05371 10.6201 7.34082C10.0915 7.62337 9.68131 8.0153 9.38965 8.5166C9.10254 9.0179 8.95898 9.57161 8.95898 10.1777H10.9414C10.9414 9.67643 11.069 9.27311 11.3242 8.96777C11.584 8.65788 11.9372 8.50293 12.3838 8.50293C12.7985 8.50293 13.1175 8.63053 13.3408 8.88574C13.5641 9.13639 13.6758 9.48275 13.6758 9.9248C13.6758 10.2484 13.5687 10.5902 13.3545 10.9502C13.1449 11.3102 12.819 11.7318 12.377 12.2148L9.15723 15.6465V17Z" fill="#000000" />
    </svg>
  );
};
