import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const CartIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <g>
          <path
            d="M5.73582 8.85313C5.8086 8.36295 6.22941 8 6.72497 8H17.275C17.7706 8 18.1914 8.36295 18.2642 8.85313L19.6703 18.3234C19.76 18.9276 19.2919 19.4703 18.6811 19.4703H5.31887C4.70811 19.4703 4.24002 18.9276 4.32972 18.3234L5.73582 8.85313Z"
            stroke="#1D1D1D"
            strokeWidth="2"
          />
          <path
            d="M15 10V6C15 4.34315 13.6569 3 12 3V3C10.3431 3 9 4.34315 9 6V10"
            stroke="#1D1D1D"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};
