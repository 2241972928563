import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const CopyIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_17339_41688)">
        <rect
          x="8.75"
          y="6.75"
          width="10.5"
          height="12.5"
          rx="1.25"
          stroke="#1D1D1D"
          strokeWidth="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 4.5H22C22.2761 4.5 22.5 4.72386 22.5 5V15C22.5 15.2761 22.2761 15.5 22 15.5H19.3846V17H22C23.1046 17 24 16.1046 24 15V5C24 3.89543 23.1046 3 22 3H14C12.8954 3 12 3.89543 12 5V6.625H13.5V5C13.5 4.72386 13.7239 4.5 14 4.5Z"
          fill="#1D1D1D"
        />
      </g>
      <defs>
        <clipPath id="clip0_17339_41688">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
