import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const CursorIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M11.864 18.5552C12.0967 19.5228 13.4461 19.5936 13.7787 18.6556L17.8044 7.30466C18.0857 6.51136 17.3209 5.74658 16.5276 6.02792L5.17666 10.0535C4.23871 10.3862 4.30947 11.7356 5.27707 11.9683L9.99163 13.1022C10.357 13.1901 10.6422 13.4753 10.7301 13.8406L11.864 18.5552Z"
        fill="#FFDD2D"
      />
    </svg>
  );
};
