import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const RemoveIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M6.0332 7.03516V16.1685C6.0332 17.8253 7.37635 19.1685 9.0332 19.1685H15.1665C16.8234 19.1685 18.1665 17.8253 18.1665 16.1685V7.03516"
        stroke="#a4a4a4"
        strokeWidth="2"
      />
      <path
        d="M16.1445 8.04297L16.1445 4.99853C16.1445 4.44624 15.6968 3.99853 15.1445 3.99852L9.05564 3.99852C8.50336 3.99852 8.05564 4.44624 8.05564 4.99852L8.05564 8.04297"
        stroke="#a4a4a4"
        strokeWidth="2"
      />
      <rect
        x="3"
        y="6.02344"
        width="18.2"
        height="2.02222"
        rx="1.01111"
        fill="#a4a4a4"
      />
      <rect
        x="11.0898"
        y="9.05469"
        width="8.08889"
        height="2.02222"
        rx="1.01111"
        transform="rotate(90 11.0898 9.05469)"
        fill="#a4a4a4"
      />
      <rect
        x="15.1328"
        y="9.05469"
        width="8.08889"
        height="2.02222"
        rx="1.01111"
        transform="rotate(90 15.1328 9.05469)"
        fill="#a4a4a4"
      />
    </svg>
  );
};
