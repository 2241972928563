import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const TextImageLeftIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      className={className}
      fill={fill ? fill : 'none'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="22" y="19" width="10" height="14" rx="2" transform="rotate(-180 22 19)" fill="#2D264B" />
      <rect x="10" y="19" width="7" height="2" rx="1" transform="rotate(-180 10 19)" fill="#2D264B" />
      <rect x="11" y="15" width="9" height="2" rx="1" transform="rotate(-180 11 15)" fill="#2D264B" />
      <rect x="10" y="11" width="7" height="2" rx="1" transform="rotate(-180 10 11)" fill="#2D264B" />
      <rect x="11" y="7" width="9" height="2" rx="1" transform="rotate(-180 11 7)" fill="#2D264B" />
    </svg>

  );
};