import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const CancelIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <circle cx="8" cy="8" r="8" fill="#AC0401" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46522 4.46447C4.0747 4.85499 4.0747 5.48816 4.46522 5.87868L6.58654 8L4.46522 10.1213C4.0747 10.5118 4.0747 11.145 4.46522 11.5355C4.85575 11.9261 5.48891 11.9261 5.87944 11.5355L8.00076 9.41421L10.1221 11.5355C10.5126 11.9261 11.1458 11.9261 11.5363 11.5355C11.9268 11.145 11.9268 10.5118 11.5363 10.1213L9.41497 8L11.5363 5.87868C11.9268 5.48816 11.9268 4.85499 11.5363 4.46447C11.1458 4.07394 10.5126 4.07394 10.1221 4.46447L8.00076 6.58579L5.87943 4.46447C5.48891 4.07394 4.85575 4.07394 4.46522 4.46447Z"
        fill="white"
      />
    </svg>
  );
};
