import { IIconComponent } from '../../../services/types/types';
import { FC } from 'react';

export const DeliveryIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M6.15 5.7002H13.7C14.2523 5.7002 14.7 6.14791 14.7 6.7002V9.65019C14.7 10.2025 15.1477 10.6502 15.7 10.6502H19.2358C19.501 10.6502 19.7554 10.7556 19.9429 10.9431L20.7071 11.7073C20.8946 11.8948 21 12.1492 21 12.4144V15.5002C21 16.0525 20.5523 16.5002 20 16.5002H3.45M3 12.4502H7.05M4.35 9.3002H7.95"
        stroke="#1D1D1D"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <path
        d="M19.6502 10.6501L18.5281 7.28387C18.392 6.87553 18.0099 6.6001 17.5794 6.6001H14.7002"
        stroke="#1D1D1D"
        strokeWidth="1.8"
      />
      <circle
        cx="8.8498"
        cy="16.5002"
        r="1.8"
        fill="none"
        stroke="#1D1D1D"
        strokeWidth="1.8"
      />
      <circle
        cx="16.9499"
        cy="16.5002"
        r="1.8"
        fill="none"
        stroke="#1D1D1D"
        strokeWidth="1.8"
      />
    </svg>
  );
};
