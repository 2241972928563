import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const HomeIcon: FC<IIconComponent> = ({ className, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M3.72656 9.89355L12.7266 2.89355L21.7266 9.89355V20.8936C21.7266 21.424 21.5158 21.9327 21.1408 22.3078C20.7657 22.6828 20.257 22.8936 19.7266 22.8936H5.72656C5.19613 22.8936 4.68742 22.6828 4.31235 22.3078C3.93728 21.9327 3.72656 21.424 3.72656 20.8936V9.89355Z"
        stroke={stroke ? stroke : "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.72656 22.8936V12.8936H15.7266V22.8936"
        stroke={stroke ? stroke : "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
