import { FC } from "react";
import { IIconComponent } from "@/services/types/types";

export const UploadFileIcon: FC<IIconComponent> = (props) => {
  const {
    className,
    fill
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill ? fill : "#000000"}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      className={className}
    >
      <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
    </svg>
  );
};