import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const RutubeIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <path
        d="M14.4088 12.3381C15.2743 11.5123 15.8298 10.3825 15.8298 8.9644V8.34756C15.8298 5.85731 14.1231 4.02764 12.0186 4.02764L6 4L6.00361 17.9735L8.84634 18V13.2572H11.7683L13.673 18H17L14.4088 12.3381ZM12.925 8.79495C12.925 9.78654 12.2451 10.3494 11.4067 10.3494H8.84647V6.99371H11.4067C12.2451 6.99371 12.925 7.69029 12.925 8.6822V8.79495Z"
        fill={fill ? fill : "#C9CED6"}
      />
    </svg>
  );
};
