import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const MainIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M8.90918 14.8184C8.90918 14.2661 9.35689 13.8184 9.90918 13.8184H14.091C14.6433 13.8184 15.091 14.2661 15.091 14.8184V19.0002C15.091 19.5525 14.6433 20.0002 14.091 20.0002H9.90918C9.35689 20.0002 8.90918 19.5525 8.90918 19.0002V14.8184Z"
        stroke="#1D1D1D"
        strokeWidth="2"
      />
      <path
        d="M4 10.0704C4 9.73601 4.1671 9.42378 4.4453 9.23832L3.8906 8.40627L4.4453 9.23832L11.4453 4.57165C11.7812 4.34772 12.2188 4.34772 12.5547 4.57165L19.5547 9.23832C19.8329 9.42378 20 9.73602 20 10.0704V19C20 19.5523 19.5523 20 19 20H13.8H10.2H5C4.44772 20 4 19.5523 4 19V10.0704Z"
        stroke="#1D1D1D"
        strokeWidth="2"
      />
    </svg>
  );
};
