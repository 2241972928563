import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const RustoreMiniIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7461 16.1116C13.7794 15.8702 13.1016 15.0054 13.1016 14.0136V2.329C13.1016 1.20249 14.166 0.376313 15.264 0.650592L22.2172 2.38759C23.1839 2.62905 23.8618 3.4938 23.8618 4.48559V16.1702C23.8618 17.2967 22.7973 18.1229 21.6993 17.8486L14.7461 16.1116Z"
        fill="white"
      />
      <path
        d="M1.66011 21.4925C0.693414 21.251 0.015625 20.3863 0.015625 19.3945V7.70986C0.015625 6.58335 1.08005 5.75717 2.17804 6.03145L9.13129 7.76845C10.0979 8.00991 10.7758 8.87466 10.7758 9.86645V21.5511C10.7758 22.6776 9.71131 23.5038 8.61332 23.2295L1.66011 21.4925Z"
        fill="white"
      />
      <path
        d="M8.20308 18.8011C7.23638 18.5596 6.55859 17.6949 6.55859 16.7031V5.01849C6.55859 3.89198 7.62306 3.06576 8.72105 3.34004L15.6743 5.07704C16.641 5.31854 17.3187 6.18329 17.3187 7.17508V18.8597C17.3187 19.9862 16.2543 20.8124 15.1563 20.5381L8.20308 18.8011Z"
        fill="white"
      />
      <path
        d="M12.7555 19.9414C12.451 19.8639 12.2343 19.5946 12.2236 19.2806L11.9212 10.3571C11.8337 9.17606 10.957 8.23678 10.0409 7.96018C9.98943 7.94464 9.93451 7.96556 9.90406 8.0098C9.87311 8.05477 9.88506 8.11703 9.92823 8.15046C10.1545 8.32558 10.7771 8.89709 10.7771 9.88282L10.7753 19.4396L12.7555 19.9414Z"
        fill="#1d1d1d"
      />
      <path
        d="M19.2985 17.2485C18.9943 17.1724 18.7773 16.9039 18.7667 16.5905L18.4641 7.66763C18.3766 6.48661 17.5 5.54733 16.5838 5.27073C16.5324 5.25519 16.4775 5.27611 16.447 5.32035C16.4161 5.36532 16.428 5.42758 16.4712 5.46101C16.6974 5.63613 17.3201 6.20764 17.3201 7.19336L17.3183 16.7534L19.2985 17.2485Z"
        fill="#1d1d1d"
      />
    </svg>
  );
};
