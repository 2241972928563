import { FC } from "react";
import { IIconComponent } from "@/services/types/types";

export const CodeIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9 8L5 11.6923L9 16M15 8L19 11.6923L15 16" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};