import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const FavouriteIcon: FC<IIconComponent> = ({ className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white"
      className={className}
    >
      <path
        d="M11.2161 18.9593L11.2146 18.958C8.6192 16.6494 6.54709 14.8037 5.11178 13.0823C3.68733 11.374 3 9.91422 3 8.39509C3 5.94419 4.95404 4 7.5 4C8.94434 4 10.3409 4.66437 11.2449 5.70569L12 6.57556L12.7551 5.70569C13.6591 4.66437 15.0557 4 16.5 4C19.046 4 21 5.94419 21 8.39509C21 9.91423 20.3127 11.3741 18.888 13.0838C17.4527 14.8064 15.3809 16.6542 12.7858 18.9675C12.7854 18.9678 12.785 18.9682 12.7846 18.9685L12.0025 19.6616L11.2161 18.9593Z"
        stroke={color || "#1D1D1D"}
        strokeWidth="2"
      />
    </svg>
  );
};
