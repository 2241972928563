import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const CardAnswerExpertIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="7" cy="7" r="7" fill="#82C21D" />
      <path
        d="M4.375 6.62533L6.63499 9.39236C6.72533 9.50297 6.89915 9.48597 6.96636 9.35996L9.625 4.375"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  );
};
