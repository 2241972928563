import { FC } from "react";
import { IIconComponent } from "@/services/types/types";

export const DropArrowIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      className={className}
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 11L6 6L1 1" stroke="#1D1D1D" strokeWidth="2" />
    </svg>
  );
};