import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const AddToCartIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M13 16C13 19.3137 10.3137 22 7 22C3.68629 22 1 19.3137 1 16C1 12.6863 3.68629 10 7 10C10.3137 10 13 12.6863 13 16ZM2.51451 16C2.51451 18.4773 4.52273 20.4855 7 20.4855C9.47727 20.4855 11.4855 18.4773 11.4855 16C11.4855 13.5227 9.47727 11.5145 7 11.5145C4.52273 11.5145 2.51451 13.5227 2.51451 16Z"
        fill="#1D1D1D"
      />
      <rect
        x="6.25"
        y="12.25"
        width="1.5"
        height="7.5"
        rx="0.75"
        fill="#1D1D1D"
      />
      <rect
        x="10.75"
        y="15.25"
        width="1.5"
        height="7.5"
        rx="0.75"
        transform="rotate(90 10.75 15.25)"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87958 5C9.87958 2.79086 11.6704 1 13.8796 1C16.0887 1 17.8796 2.79086 17.8796 5V6.00018H19.1546C20.1457 6.00018 20.9874 6.72608 21.1329 7.70645L22.539 17.1767C22.7184 18.385 21.7822 19.4705 20.5607 19.4705H11.7804C12.2033 18.8751 12.5202 18.1993 12.7039 17.4705L20.5607 17.4705L19.1546 8.00018H17.8796V9C17.8796 9.55228 17.4319 10 16.8796 10C16.3273 10 15.8796 9.55228 15.8796 9V8.00018H11.8796V9C11.8796 9.55228 11.4319 10 10.8796 10C10.3273 10 9.87958 9.55228 9.87958 9V8.00018H8.60456L8.28332 10.1637C7.83478 10.0567 7.36669 10 6.88536 10C6.68145 10 6.47992 10.0102 6.28125 10.03L6.62624 7.70645C6.77181 6.72607 7.61343 6.00018 8.60456 6.00018H9.87958V5ZM15.8796 5V6.00018H11.8796V5C11.8796 3.89543 12.775 3 13.8796 3C14.9842 3 15.8796 3.89543 15.8796 5Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};
