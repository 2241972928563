import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const ArrowIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      className={className}
    >
      <path
        d="M8 1L2 6.5L8 12"
        stroke={fill ? fill : "#7F7F7F"}
        strokeWidth="2"
      />
    </svg>
  );
};
