import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const ShopsIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6.67969 11.3604L13.3161 11.3604V17.9967H6.67969L6.67969 11.3604Z" stroke="#1D1D1D" strokeWidth="2" />
      <path d="M1.5 6.86852L10 1.20185L18.5 6.86852L18.5 18H11.9H8.1H1.5L1.5 6.86852Z" stroke="#1D1D1D" strokeWidth="2" />
    </svg>
  );
};