import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const VisaIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="13"
      viewBox="0 0 38 13"
      fill="none"
      className={className}
    >
      <path
        d="M24.7508 0.148438C22.0526 0.148438 19.6415 1.51147 19.6415 4.02977C19.6415 6.91779 23.9178 7.11726 23.9178 8.56812C23.9178 9.17901 23.1995 9.72586 21.9726 9.72586C20.2315 9.72586 18.9302 8.96175 18.9302 8.96175L18.3734 11.503C18.3734 11.503 19.8725 12.1484 21.8628 12.1484C24.8127 12.1484 27.1339 10.7185 27.1339 8.15712C27.1339 5.10541 22.8398 4.91186 22.8398 3.56522C22.8398 3.08668 23.4295 2.56233 24.6528 2.56233C26.0331 2.56233 27.1592 3.11805 27.1592 3.11805L27.7041 0.663633C27.7041 0.663633 26.4788 0.148438 24.7508 0.148438ZM0.0653329 0.333677L0 0.704154C0 0.704154 1.13512 0.906629 2.15747 1.31052C3.47384 1.77367 3.56761 2.04329 3.78931 2.88071L6.20514 11.9574H9.44358L14.4326 0.333677H11.2016L7.99586 8.23672L6.68771 1.53773C6.56775 0.771035 5.96007 0.333677 5.21624 0.333677H0.0653329ZM15.7319 0.333677L13.1972 11.9574H16.2783L18.804 0.333677H15.7319ZM32.9159 0.333677C32.173 0.333677 31.7793 0.72135 31.4905 1.3988L26.9766 11.9574H30.2076L30.8327 10.1976H34.769L35.1491 11.9574H38L35.5129 0.333677H32.9159ZM33.3361 3.47405L34.2938 7.83585H31.728L33.3361 3.47405Z"
        fill={fill ? fill : "#F7F8F9"}
      />
    </svg>
  );
};
