import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const WorktimeIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <circle cx="12" cy="12" r="8" stroke="#1D1D1D" strokeWidth="2" />
      <path
        d="M12 8V12H15.5"
        stroke="#1D1D1D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
