import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const YandexMiniLogoIcon: FC<IIconComponent> = (props) => {
  const { className } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M12 0C6.69798 0 2.40002 4.29787 2.40002 9.59983C2.40002 12.2499 3.47429 14.6497 5.21045 16.3867C6.94748 18.1245 11.0401 20.6395 11.2801 23.2801C11.3165 23.676 11.6032 24 12 24C12.3977 24 12.6844 23.676 12.7208 23.2801C12.9608 20.6395 17.0526 18.1245 18.7896 16.3867C20.5266 14.6497 21.6 12.2499 21.6 9.59983C21.6 4.29787 17.3021 0 12 0Z" fill="#FF4433" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0269 12.9968C13.8879 12.9968 15.396 11.4878 15.396 9.62772C15.396 7.76681 13.8879 6.25775 12.0269 6.25775C10.166 6.25775 8.65784 7.76681 8.65784 9.62772C8.65784 11.4878 10.166 12.9968 12.0269 12.9968Z" fill="white" />
    </svg>

  );
};