import { IIconComponent } from "@/services/types/types";
import Link from "next/link";
import { FC } from "react";

export const TicketActiveIcon: FC<IIconComponent> = ({ className, code, name, ticket }) => {
  return (
    // <svg
    //   className={className}
    //   width="450"
    //   height="222"
    //   viewBox="0 0 450 222"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g clipPath="url(#clip0_514_984)">
    //     <path
    //       d="M304 0H16A16 16 0 0 0 0 16V184A16 16 0 0 0 16 200H324V0Z"
    //       fill="#ffdd2d"
    //     />
    //     <path
    //       d="M320.641 200.477L313.654 188.181L325.95 181.194L318.964 168.898L331.26 161.912L324.274 149.616L336.57 142.63L329.583 130.334L341.879 123.347L334.893 111.051L347.189 104.065L340.203 91.7691L352.499 84.7828L345.513 72.4868L357.809 65.5005L350.822 53.2045L363.118 46.2182L356.132 33.9222L368.428 26.9359L361.442 14.6399L373.738 7.65357L320.641 200.477Z"
    //       fill="#fcc521"
    //     />
    //     <path
    //       d="M449.864 28.8951L372.734 7.65625L319.637 200.479L396.767 221.718L449.864 28.8951Z"
    //       fill="#fcc521"
    //     />
    //     <path
    //       d="M320 0L330 10L320 20L330 30L320 40L330 50L320 60L330 70L320 80L330 90L320 100L330 110L320 120L330 130L320 140L330 150L320 160L330 170L320 180L330 190L320 200V0Z"
    //       fill="#FFCC00"
    //     />
    //     <path
    //       d="M320 10C325.523 10 330 5.52285 330 0C330 -5.52285 325.523 -10 320 -10C314.477 -10 310 -5.52285 310 0C310 5.52285 314.477 10 320 10Z"
    //       fill="#FFFFFF"
    //     />
    //     <path
    //       d="M370.087 17.2976C375.412 18.7638 380.917 15.6359 382.383 10.3113C382.383 10.3113 378.067 9.12108 372.743 7.65485C367.418 6.18862 363.101 5.00157 363.101 5.00157C361.635 10.3262 364.763 15.8313 370.087 17.2976Z"
    //       fill="#FFFFFF"
    //     />
    //     <path
    //       d="M320 200H330C330 194.477 325.523 190 320 190C314.477 190 310 194.477 310 200H320Z"
    //       fill="#FFFFFF"
    //     />
    //     <path
    //       d="M320 10V190"
    //       stroke="black"
    //       strokeWidth="2"
    //       strokeDasharray="5 5"
    //     />
    //     <path
    //       d="M319.643 200.476L329.284 203.131C330.75 197.806 327.622 192.301 322.297 190.835C316.973 189.368 311.468 192.496 310.001 197.821L319.643 200.476Z"
    //       fill="#FFFFFF"
    //     />
    //     <foreignObject x="0" y="0" width="320" height="200">
    //       <div
    //         style={{
    //           width: "100%",
    //           height: "100%",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",

    //           flexDirection: "column",
    //           textAlign: "left",
    //           padding: "10px",
    //           boxSizing: "border-box",
    //         }}
    //       >
    //         <p
    //           style={{
    //             fontSize: "20px",
    //             color: "#000",
    //             margin: 0,
    //           }}
    //         >
    //           {name}
    //         </p>
    //         <Link href={`/giveaway/${code}`}>Подпробнее</Link>
    //       </div>
    //     </foreignObject>
    //     <text
    //       x="360"
    //       y="100"
    //       fontSize="20"
    //       fill="#000"
    //       textAnchor="middle"
    //       dominantBaseline="middle"
    //       transform="rotate(-74 386, 93)"
    //     >
    //       №{ticket}
    //     </text>
    //   </g>
    // </svg>
    <svg
      className={className}
      width="450"
      height="222"
      viewBox="0 0 450 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M324 0L334 10L324 20L334 30L324 40L334 50L324 60L334 70L324 80L334 90L324 100L334 110L324 120L334 130L324 140L334 150L324 160L334 170L324 180L334 190L324 200V0Z" fill="#FFDD2D" />
      <path d="M324 10V190" stroke="black" strokeWidth="3" stroke-dasharray="2 2" />
      <path d="M304 0H16C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16L0 184C0 188.243 1.68571 192.313 4.68629 195.314C7.68687 198.314 11.7565 200 16 200H324V0H304Z" fill="#FFDD2D" />
      <path d="M324 200H334C334 194.477 329.523 190 324 190C318.477 190 314 194.477 314 200H324Z" fill="white" />
      <path d="M324 -8.74228e-07L314 -1.74846e-06C314 5.523 318.477 10 324 10C329.523 10 334 5.523 334 0L324 -8.74228e-07Z" fill="white" />
      <foreignObject x="0" y="0" width="320" height="200">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            flexDirection: "column",
            textAlign: "left",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              color: "#000",
              margin: 0,
            }}
          >
            {name}
          </p>
          <Link prefetch={false} href={`/giveaway/${code}`}>Подпробнее</Link>
        </div>
      </foreignObject>
    </svg>

  );
};