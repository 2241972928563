
import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const TgColorIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="32" height="32" rx="8" fill="#34ACE0" />
      <path d="M11 16.7553L12.7863 21.5513C12.7863 21.5513 13.0096 22 13.2487 22C13.4879 22 17.0447 18.4107 17.0447 18.4107L21 11L11.0637 15.5173L11 16.7553Z" fill="#C8DAEA" />
      <path d="M10.9338 17.5775L7.32038 16.4179C7.32038 16.4179 6.88853 16.2453 7.02759 15.854C7.05621 15.7733 7.11396 15.7046 7.28669 15.5866C8.08733 15.0369 22.1058 10.074 22.1058 10.074C22.1058 10.074 22.5017 9.94261 22.7351 10.03C22.7928 10.0476 22.8448 10.08 22.8857 10.1239C22.9265 10.1678 22.9548 10.2215 22.9677 10.2797C22.9929 10.3825 23.0034 10.4882 22.999 10.5939C22.9979 10.6853 22.9867 10.77 22.9782 10.9028C22.8928 12.2597 20.3377 22.3866 20.3377 22.3866C20.3377 22.3866 20.1849 22.9792 19.6372 22.9995C19.5026 23.0038 19.3685 22.9813 19.2429 22.9335C19.1173 22.8856 19.0028 22.8133 18.9061 22.7209C17.8313 21.8103 14.1165 19.3513 13.2956 18.8104C13.2771 18.798 13.2615 18.7818 13.2499 18.763C13.2383 18.7441 13.2309 18.723 13.2282 18.7011C13.2168 18.6441 13.2797 18.5735 13.2797 18.5735C13.2797 18.5735 19.7482 12.9101 19.9203 12.3156C19.9337 12.2696 19.8833 12.2468 19.8157 12.267C19.3861 12.4227 11.9384 17.0553 11.1164 17.5666C11.0572 17.5842 10.9947 17.588 10.9338 17.5775Z" fill="white" />
    </svg>
  );
};
