import { FC } from "react";
import { IIconComponent } from "@/services/types/types";

export const OneSquareIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      className={className}
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2H22V22H2V2ZM11.9805 17H13.9561V7.0332H13.7441L9.62207 8.50977V10.1162L11.9805 9.38477V17Z"
        fill="#000000"
      />
    </svg>
  );
};