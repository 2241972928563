import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const ShareIcon: FC<IIconComponent> = ({ className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path d="M17 5L6 11L17 17" stroke={color || "#393939"} strokeWidth="2" />
      <circle
        cx="16"
        cy="17"
        r="3"
        transform="rotate(-90 16 17)"
        fill="white"
        stroke={color || "#393939"}
        strokeWidth="2"
      />
      <circle
        cx="16"
        cy="5"
        r="3"
        transform="rotate(-90 16 5)"
        fill="white"
        stroke={color || "#393939"}
        strokeWidth="2"
      />
      <circle
        cx="6"
        cy="11"
        r="3"
        transform="rotate(-90 6 11)"
        fill="white"
        stroke={color || "#393939"}
        strokeWidth="2"
      />
    </svg>
  );
};
