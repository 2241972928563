import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const PlusIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="11" y="2" width="2" height="20" rx="1" fill="black" />
      <path d="M21 11C21.5523 11 22 11.4477 22 12V12C22 12.5523 21.5523 13 21 13L3 13C2.44772 13 2 12.5523 2 12V12C2 11.4477 2.44772 11 3 11L21 11Z" fill="black" />
    </svg>
  );
};
