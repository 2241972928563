import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const QuestionChatIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      className={className}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="#FFE53E" />
      <path
        d="M14.4 24.6474V23.8474H13.6H13C11.785 23.8474 10.8 22.8624 10.8 21.6474V14C10.8 12.785 11.785 11.8 13 11.8H25C26.215 11.8 27.2 12.785 27.2 14V21.6474C27.2 22.8624 26.215 23.8474 25 23.8474H20.8H20.577L20.3861 23.9628L14.4 27.5816V24.6474Z"
        stroke="black"
        strokeWidth="1.6"
      />
    </svg>
  );
};
