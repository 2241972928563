import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const MenuIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M4.5 5H18.5M4.5 11H10.5M4.5 17H7.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="16.5002"
        cy="14.0002"
        r="4.00016"
        stroke="#1D1D1D"
        strokeWidth="2"
      />
      <path
        d="M19.6255 17.1255L22 19.5"
        stroke="#1D1D1D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
