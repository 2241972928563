import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const ResizeImageIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      className={className}
      fill={fill}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4161 9.68168C14.2208 9.48642 14.2208 9.16983 14.4161 8.97457L22.1901 1.20053C22.3854 1.00527 22.7019 1.00527 22.8972 1.20053V1.20053C23.0925 1.39579 23.0925 1.71237 22.8972 1.90763L15.1232 9.68168C14.9279 9.87694 14.6113 9.87694 14.4161 9.68168V9.68168Z"
        fill="black" />
      <path
        d="M22.5859 1C22.8621 1 23.0859 1.22386 23.0859 1.5L23.0859 6.5C23.0859 6.77614 22.8621 7 22.5859 7V7C22.3098 7 22.0859 6.77614 22.0859 6.5L22.0859 1.5C22.0859 1.22386 22.3098 1 22.5859 1V1Z"
        fill="black" />
      <path
        d="M17.0781 1.36719C17.0781 1.09105 17.302 0.867188 17.5781 0.867188L22.5781 0.867188C22.8543 0.867188 23.0781 1.09105 23.0781 1.36719V1.36719C23.0781 1.64333 22.8543 1.86719 22.5781 1.86719H17.5781C17.302 1.86719 17.0781 1.64333 17.0781 1.36719V1.36719Z"
        fill="black" />
      <path
        d="M9.56449 9.63863C9.36923 9.8339 9.05265 9.8339 8.85738 9.63863L1.07943 1.86069C0.884172 1.66542 0.884173 1.34884 1.07943 1.15358V1.15358C1.2747 0.958319 1.59128 0.958319 1.78654 1.15358L9.56449 8.93153C9.75975 9.12679 9.75975 9.44337 9.56449 9.63863V9.63863Z"
        fill="black" />
      <path
        d="M1 1.5C1 1.22386 1.22386 1 1.5 1L6.5 1C6.77614 1 7 1.22386 7 1.5V1.5C7 1.77614 6.77614 2 6.5 2L1.5 2C1.22386 2 1 1.77614 1 1.5V1.5Z"
        fill="black" />
      <path
        d="M1.25781 7C0.98167 7 0.757813 6.77614 0.757813 6.5L0.757813 1.5C0.757813 1.22386 0.98167 1 1.25781 1V1C1.53395 1 1.75781 1.22386 1.75781 1.5V6.5C1.75781 6.77614 1.53395 7 1.25781 7V7Z"
        fill="black" />
      <path
        d="M9.59176 14.3339C9.78702 14.5292 9.78702 14.8458 9.59176 15.0411L1.81381 22.819C1.61855 23.0143 1.30197 23.0143 1.10671 22.819V22.819C0.911445 22.6237 0.911445 22.3072 1.10671 22.1119L8.88465 14.3339C9.07992 14.1387 9.3965 14.1387 9.59176 14.3339V14.3339Z"
        fill="black" />
      <path
        d="M1.4375 23C1.16136 23 0.9375 22.7761 0.9375 22.5L0.9375 17.5C0.9375 17.2239 1.16136 17 1.4375 17V17C1.71364 17 1.9375 17.2239 1.9375 17.5L1.9375 22.5C1.9375 22.7761 1.71364 23 1.4375 23V23Z"
        fill="black" />
      <path
        d="M7 22.6484C7 22.9246 6.77614 23.1484 6.5 23.1484L1.5 23.1484C1.22386 23.1484 1 22.9246 1 22.6484V22.6484C1 22.3723 1.22386 22.1484 1.5 22.1484L6.5 22.1484C6.77614 22.1484 7 22.3723 7 22.6484V22.6484Z"
        fill="black" />
      <path
        d="M14.3418 14.4082C14.537 14.213 14.8536 14.213 15.0489 14.4082L22.8268 22.1862C23.0221 22.3815 23.0221 22.698 22.8268 22.8933V22.8933C22.6316 23.0886 22.315 23.0886 22.1197 22.8933L14.3418 15.1153C14.1465 14.9201 14.1465 14.6035 14.3418 14.4082V14.4082Z"
        fill="black" />
      <path
        d="M23.0078 22.6484C23.0078 22.9246 22.784 23.1484 22.5078 23.1484L17.5078 23.1484C17.2317 23.1484 17.0078 22.9246 17.0078 22.6484V22.6484C17.0078 22.3723 17.2317 22.1484 17.5078 22.1484L22.5078 22.1484C22.784 22.1484 23.0078 22.3723 23.0078 22.6484V22.6484Z"
        fill="black" />
      <path
        d="M22.6406 17C22.9168 17 23.1406 17.2239 23.1406 17.5L23.1406 22.5C23.1406 22.7761 22.9168 23 22.6406 23V23C22.3645 23 22.1406 22.7761 22.1406 22.5L22.1406 17.5C22.1406 17.2239 22.3645 17 22.6406 17V17Z"
        fill="black" />
    </svg>
  );
};