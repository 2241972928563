import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const DateIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 6.75V4.25C1 3.69772 1.44772 3.25 2 3.25H13C13.5523 3.25 14 3.69772 14 4.25V6.75M1 6.75V15.25C1 15.8023 1.44772 16.25 2 16.25H13C13.5523 16.25 14 15.8023 14 15.25V6.75M1 6.75H14"
        stroke="#494C4F"
        strokeWidth="2"
      />
      <path
        d="M3.5 3.25V2L3.5 0.75M11.5 3.25V0.75"
        stroke="#494C4F"
        strokeWidth="2"
      />
    </svg>
  );
};
