import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const CallbackIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M19.1043 20.2115C10.4792 21.6519 2.73323 13.906 4.17362 5.28094C4.25032 4.82164 4.65872 4.5 5.12438 4.5H9.11843C9.61608 4.5 10.038 4.86593 10.1084 5.35858L10.5748 8.62356C10.6431 9.10199 10.3596 9.56084 9.90108 9.71366L9.36326 9.89294C8.90477 10.0458 8.61323 10.5084 8.73713 10.9755C9.48641 13.8005 11.7941 15.1485 13.4158 15.6312C13.879 15.7691 14.3395 15.4804 14.4923 15.0219L14.6716 14.4841C14.8244 14.0256 15.2833 13.7421 15.7617 13.8105L19.0267 14.2768C19.5193 14.3472 19.8852 14.7692 19.8852 15.2668V19.2609C19.8852 19.7265 19.5636 20.1348 19.1043 20.2115Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M16 9L13 5.93333L16 3"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 11V7.81818C20 7.33597 19.8156 6.87351 19.4874 6.53253C19.1592 6.19156 18.7141 6 18.25 6H13"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
