import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const YaColorIcon: FC<IIconComponent> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect width="32" height="32" rx="8" fill="#FC3F1D" />
      <path
        d="M17.993 24.7115H21.1553V6.57812H16.5519C11.9218 6.57812 9.49339 8.94797 9.49339 12.4495C9.49339 15.4717 11.0679 17.1759 13.8566 19.0265L15.5512 20.1448L13.3762 18.3209L9.06641 24.7115H12.4956L17.099 17.8416L15.4978 16.7765C13.563 15.4717 12.6157 14.4599 12.6157 12.2631C12.6157 10.3326 13.9767 9.02786 16.5653 9.02786H17.9796V24.7115H17.993Z"
        fill="white"
      />
    </svg>
  );
};
