import { FC } from "react";
import { IIconComponent } from "@/services/types/types";

export const ListBulletedIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.375 11.5C9.41053 11.5 10.25 10.6605 10.25 9.625C10.25 8.58947 9.41053 7.75 8.375 7.75C7.33947 7.75 6.5 8.58947 6.5 9.625C6.5 10.6605 7.33947 11.5 8.375 11.5Z"
        fill="#212529" />
      <path
        d="M8.375 20.25C9.41053 20.25 10.25 19.4105 10.25 18.375C10.25 17.3395 9.41053 16.5 8.375 16.5C7.33947 16.5 6.5 17.3395 6.5 18.375C6.5 19.4105 7.33947 20.25 8.375 20.25Z"
        fill="#212529" />
      <path d="M14 17.75H22.75V19H14V17.75ZM14 9H22.75V10.25H14V9Z" fill="#212529" />
    </svg>
  );
};
