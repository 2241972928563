import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const NewLogoOpacityIcon: FC<IIconComponent> = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="375"
      height="225"
      viewBox="0 0 375 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.44">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.572 113.484L155.85 224.857L73.1332 224.89C72.3037 224.893 68.9858 224.953 68.1297 224.907C54.2553 224.514 35.2075 212.295 25.4304 202.521C20.0705 197.008 15.7599 191.361 12.492 185.485C9.21743 179.609 6.69239 172.55 4.75697 164.362C2.89816 156.184 1.62896 148.152 0.962721 140.267C0.29315 132.462 0 123.458 0 113.271C0 103.081 0.29315 94.0834 0.962721 86.2717C1.63229 78.3934 2.89816 70.3585 4.75697 62.1771C6.69239 53.9989 9.21743 46.9368 12.492 41.0572C15.7632 35.1809 20.0705 29.5279 25.4304 24.0247C30.8603 18.5948 37.3328 14.2043 44.8446 10.8598C52.4264 7.51189 61.5007 4.83359 72.0639 2.90149C82.7038 0.962727 94.6728 0 108.061 0C110.862 0 113.604 0.0433106 116.282 0.129922C118.977 0.0466418 121.732 0 124.547 0C137.932 0 149.907 0.962727 160.544 2.90149C171.104 4.83026 180.178 7.51189 187.76 10.8598C195.272 14.2043 201.744 18.5948 207.174 24.0247C208.953 25.8535 209.872 26.7296 210.878 27.8822L169.918 88.1971C160.208 67.477 139.434 54.2654 116.306 54.2588L115.1 54.2754H115.086H115.013H114.936H114.793C82.7537 55.0816 57.0269 81.3082 57.0269 113.544C57.0269 145.777 82.7537 172 114.793 172.807H114.82C115.313 172.82 115.813 172.83 116.312 172.83H116.609H116.669H116.885L116.938 172.827H117.018L117.072 172.823H117.195H117.252H117.288L117.338 172.82H117.422H117.471H117.511H117.561H117.598H117.655H117.691L117.741 172.817H117.778L117.961 172.813H118.004L119.16 172.763C138.851 144.265 158.482 115.646 178.103 87.1978L232.351 7.03885L375 224.817L304.755 225L281.993 190.152L217.624 191.418L250.903 142.286L231.582 113.491L231.572 113.484Z"
          fill="#1D1D1D"
        />
      </g>
    </svg>
  );
};