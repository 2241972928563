import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const SuccessIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2ZM9.385 14.66H8.045L4.805 10.12L6.146 8.87L8.715 11.27L13.856 5.339L15.196 6.279L9.385 14.66Z"
        fill="#82C21D"
      />
    </svg>
  );
};
