import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const DzenIcon: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.2697C5.64846 11.315 7.69911 11.5641 9.0675 12.9325C10.4359 14.3009 10.685 16.3515 10.7303 20L11.2697 20C11.3151 16.3515 11.5641 14.3009 12.9325 12.9325C14.3009 11.5641 16.3515 11.315 20 11.2697L20 10.7303C16.3515 10.685 14.3009 10.4359 12.9325 9.06751C11.5641 7.6991 11.3151 5.64848 11.2697 2L10.7303 2C10.6849 5.64848 10.4359 7.6991 9.0675 9.06751C7.69911 10.4359 5.64846 10.685 2 10.7303L2 11.2697Z"
        fill={fill ? fill : "#C9CED6"}
      />
    </svg>
  );
};
