import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const RepeatIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.0102 2.48996C5.90654 2.48996 4.07742 3.67205 3.1511 5.41264C3.12714 5.45765 3.10379 5.50304 3.08106 5.54878L1.7491 4.88495C1.778 4.82679 1.80769 4.76909 1.83814 4.71186C3.01266 2.5049 5.33536 1 8.0102 1C11.8706 1 15 4.13401 15 8C15 11.866 11.8706 15 8.0102 15C5.93773 15 4.07517 14.0958 2.79623 12.6622L3.90571 11.6695C4.91434 12.8001 6.37899 13.51 8.0102 13.51C11.0489 13.51 13.5122 11.0431 13.5122 8C13.5122 4.95689 11.0489 2.48996 8.0102 2.48996Z"
          fill="#7F7F7F"
        />
        <path
          d="M7.32404 4.38911H8.24792V8.09839H11.0196V9.02571H7.32404V4.38911Z"
          fill="#7F7F7F"
        />
        <path
          d="M1.70931 7.00188L1 4.29592L4.16241 5.66337L1.70931 7.00188Z"
          fill="#7F7F7F"
        />
      </g>
    </svg>
  );
};
