import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const FilterIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g>
        <g>
          <path
            d="M3 8.5L21 8.5"
            stroke="#1D1D1D"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M21 15.5L3 15.5"
            stroke="#1D1D1D"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M11 8.5C11 9.88071 9.88071 11 8.5 11C7.11929 11 6 9.88071 6 8.5C6 7.11929 7.11929 6 8.5 6C9.88071 6 11 7.11929 11 8.5Z"
            fill="white"
            stroke="#1D1D1D"
            strokeWidth="2"
          />
          <path
            d="M13 15.5C13 14.1193 14.1193 13 15.5 13C16.8807 13 18 14.1193 18 15.5C18 16.8807 16.8807 18 15.5 18C14.1193 18 13 16.8807 13 15.5Z"
            fill="white"
            stroke="#1D1D1D"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
