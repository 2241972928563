import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const ArrowBackIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M2.79289 11.2929C2.40237 11.6834 2.40237 12.3166 2.79289 12.7071L9.15685 19.0711C9.54738 19.4616 10.1805 19.4616 10.5711 19.0711C10.9616 18.6805 10.9616 18.0474 10.5711 17.6569L4.91421 12L10.5711 6.34315C10.9616 5.95262 10.9616 5.31946 10.5711 4.92893C10.1805 4.53841 9.54738 4.53841 9.15685 4.92893L2.79289 11.2929ZM20.5 13C21.0523 13 21.5 12.5523 21.5 12C21.5 11.4477 21.0523 11 20.5 11V13ZM3.5 13H20.5V11H3.5V13Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};
