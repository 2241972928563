import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const CheckedIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      className={className}
    >
      <path
        d="M1.04999 3.6499L5.09999 7.6999L11.4 1.3999"
        stroke="#2D2A28"
        strokeWidth="2"
      />
    </svg>
  );
};
