import { IIconComponent } from "@/services/types/types"
import { FC } from "react"

export const ArrowSearch: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM18 9C18.5523 9 19 8.55228 19 8C19 7.44772 18.5523 7 18 7V9ZM1 9H18V7H1V9Z"
        fill={fill ? fill : "#7F7F7F"}
      />
    </svg>

  )
}