import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const BoxIcon: FC<IIconComponent> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 11.7838V9.43339C8 9.02469 8.24871 8.65711 8.6281 8.50512L15.189 5.87659C15.4245 5.78223 15.6871 5.78093 15.9236 5.87296L20 7.45946M20 7.45946L12.6461 10.242C12.2573 10.3891 12 10.7615 12 11.1773V20M20 7.45946V16.2822C20 16.6979 19.7427 17.0703 19.3539 17.2174L12 20M12 20L4.64611 17.2174C4.25725 17.0703 4 16.6979 4 16.2822V7.71784C4 7.30208 4.25725 6.92969 4.64611 6.78255L12 4"
        stroke="#1D1D1D"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
};
