import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const OpenArrowIcon: FC<IIconComponent> = ({className, fill}) => {
  return (
    <svg
      width="12"
      height="28"
      viewBox="0 0 12 28"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M3 8.00031L6 2.00031C6 2.00031 6.75 0.499694 9 0.5C11.25 0.500306 12.053 1.00498 11.5 2.00031C9 6.5 5.5 12 5.5 14.0003C5.5 16.0006 10 23 11.5 26.0003C12.0091 27.0186 11 27.5 9 27.5C7 27.5 6 26.0003 6 26.0003L3 20.5003C3 20.5003 4.74153e-07 15.0006 2.37077e-07 14.0003C0 13 3 8.00031 3 8.00031Z"
        fill="black" />
    </svg>
  );
};