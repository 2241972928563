import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const BigTriangle: FC<IIconComponent> = ({ className, fill }) => {
  return (
    <svg
      className={className}
      width="162"
      height="269"
      viewBox="0 0 162 269"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.3942 1.59102L161.277 266.332C161.556 267.291 160.836 268.25 159.837 268.25H2.20102C1.19334 268.25 0.472288 267.276 0.766284 266.312L81.519 1.5717C81.9541 0.145252 83.9783 0.158837 84.3942 1.59102Z"
        fill={fill ? fill : '#F36464'} stroke="black" />
    </svg>
  );
};