import { IIconComponent } from "@/services/types/types";
import { FC } from "react";

export const AppStoreMiniIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="29"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1695 17.7191C23.1308 13.5292 26.6881 11.4908 26.8508 11.3959C24.8361 8.53203 21.7135 8.14073 20.6161 8.10953C17.9935 7.84043 15.4495 9.63963 14.1135 9.63963C12.7508 9.63963 10.6935 8.13553 8.47615 8.17973C5.62281 8.22263 2.95348 9.83333 1.48948 12.3345C-1.53185 17.4344 0.721482 24.9289 3.61615 29.0512C5.06415 31.0701 6.75615 33.3243 8.97082 33.245C11.1375 33.1579 11.9468 31.8982 14.5615 31.8982C17.1521 31.8982 17.9121 33.245 20.1708 33.1943C22.4961 33.1579 23.9601 31.1663 25.3575 29.1292C27.0308 26.8152 27.7028 24.5363 27.7295 24.4193C27.6748 24.4011 23.2135 22.741 23.1695 17.7191Z"
        fill="#fff"
      />
      <path
        d="M18.9028 5.39773C20.0681 3.97683 20.8655 2.04373 20.6441 0.0820312C18.9575 0.154831 16.8481 1.21953 15.6335 2.60923C14.5588 3.83383 13.5988 5.84103 13.8468 7.72863C15.7415 7.86643 17.6868 6.79653 18.9028 5.39773Z"
        fill="#fff"
      />
    </svg>
  );
};
