import { FC } from "react";
import { IIconComponent } from "@/services/types/types";

export const AlignRightIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 6.75L19 6.75C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75Z"
        fill="#2D264B" />
      <path
        d="M13 10.75L19 10.75C19.4142 10.75 19.75 10.4142 19.75 10C19.75 9.58579 19.4142 9.25 19 9.25L13 9.25C12.5858 9.25 12.25 9.58579 12.25 10C12.25 10.4142 12.5858 10.75 13 10.75Z"
        fill="#2D264B" />
      <path
        d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75L19 14.75C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25L5 13.25Z"
        fill="#2D264B" />
      <path
        d="M13 18.75L19 18.75C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25L13 17.25C12.5858 17.25 12.25 17.5858 12.25 18C12.25 18.4142 12.5858 18.75 13 18.75Z"
        fill="#2D264B" />
    </svg>
  );
};