import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";

export const PaySuccessIcon: FC<IIconComponent> = ({ className }) => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="29.5" cy="29.5" r="26.5" stroke="#1AB28F" strokeWidth="6" />
      <path d="M16 29L25.5 38.5L44 20" stroke="#1AB28F" strokeWidth="8" />
    </svg>
  );
};
